import { message } from 'antd';
import { type AxiosError } from 'axios';
import Cookies from 'universal-cookie';

import { configCookiesOptions } from '@/configs/cookies/cookies.config';
import { kStorageKey } from '@/constants/common';
import { useAuthStore } from '@/stores/auth';
import type { TCommonAPIError } from '@/types/common';

import Http from './axios.config';
import { env } from './environment.config';

const cookies = new Cookies();

const { accessToken: at, refreshToken: rt } = kStorageKey.Cookies;

class KodaService {
  // * If there are more than one API url, you can add more Http construction just like `base` on below.
  base: Http;
  geo: Http;
  constructor() {
    this.base = new Http(
      {
        baseURL: env.BASE_URL,
        headers: {
          ...(cookies.get(at) && {
            Authorization: `Bearer ${cookies.get(at)}`,
          }),
        },
      },
      {
        onRequest: (config) => {
          const accessToken = cookies.get(at);
          if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
          }
          return config;
        },
        onError: this.onError,
      }
    );
    this.geo = new Http(
      {
        baseURL: env.GEO_URL,
        headers: {
          Authorization: null,
        },
      },
      { onError: this.onError }
    );
  }

  updateAuthorizationToken(token: string | null) {
    KodaService?.prototype?.base?.updateConfig({
      headers: { Authorization: token ? `Bearer ${token}` : null },
    });
  }

  // * Arguments below is bare minimum, you can change it according what you need. For example, if you need idToken, you can add there
  // * Better to split the type to other files, this is only an example.
  setCredential({
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken?: string;
  }) {
    cookies.set(at, accessToken, configCookiesOptions);
    cookies.set(rt, refreshToken, configCookiesOptions);

    KodaService?.prototype?.updateAuthorizationToken(accessToken); // * This is for enabling fetch data with token when access token is added (login).
  }

  removeCredential() {
    cookies.remove(at, configCookiesOptions);
    cookies.remove(rt, configCookiesOptions);

    useAuthStore.setState((state) => ({ ...state, isAuthenticated: false }));

    KodaService?.prototype?.updateAuthorizationToken(null); // * This is for disabling fetch data with token when access token is added (logout).
  }

  onError(error: AxiosError<TCommonAPIError>) {
    const { config } = error;
    if (!config) return Promise.reject(error);

    const data = error.response?.data;

    // handle token expired
    if (data?.message === 'jwt expired') {
      KodaService?.prototype?.removeCredential();
      message.open({
        content: 'Session expired, please re-login!',
        type: 'error',
      });

      window.location.reload();
    }

    return Promise.reject(error);
  }
}

const kodaService = new KodaService();

export default kodaService;
