import { Suspense, lazy } from 'react';

import { KDErrorBoundary } from '@/components';
import { KDLayoutDashboardLoader } from '@/layouts';

const StoresPage = lazy(() => import('./Stores.page'));

export const Stores = () => {
  return (
    <KDErrorBoundary>
      <Suspense fallback={<KDLayoutDashboardLoader />}>
        <StoresPage />
      </Suspense>
    </KDErrorBoundary>
  );
};
