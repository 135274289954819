import { Suspense, lazy } from 'react';

import { KDErrorBoundary } from '@/components';
import { KDLayoutDashboardLoader } from '@/layouts';

const PaymentsPage = lazy(() => import('./Payment.page'));

export const Payments = () => {
  return (
    <KDErrorBoundary>
      <Suspense fallback={<KDLayoutDashboardLoader />}>
        <PaymentsPage />
      </Suspense>
    </KDErrorBoundary>
  );
};
