import { ThemeConfig } from 'antd';

import {
  kColorBackground,
  kColorNeutral,
  kColorPrimary,
} from '@/constants/theme';

const inputSizeObj = {
  controlHeight: 40,
  fontSize: 14,
};

export const antdTheme: ThemeConfig = {
  components: {
    Layout: {
      siderBg: kColorBackground.dashboard,
      triggerBg: 'transparent',
      triggerColor: kColorNeutral.main,
    },
    Button: {
      colorPrimary: kColorNeutral.main,
      controlHeight: 40,
    },
    Menu: {
      itemActiveBg: kColorNeutral.main,
      itemSelectedBg: kColorNeutral.main,
      itemSelectedColor: kColorNeutral[0],
    },
    Pagination: {
      itemActiveBg: kColorNeutral.main,
      colorPrimary: kColorNeutral[0],
    },
    Input: {
      ...inputSizeObj,
      activeBg: kColorNeutral[0],
      hoverBg: kColorNeutral[0],
    },
    Select: inputSizeObj,
    DatePicker: inputSizeObj,
  },
  token: {
    borderRadius: 8,
    colorLink: kColorPrimary.main,
    colorPrimary: kColorPrimary.main,
    colorText: kColorNeutral.main,
    fontFamily: 'inherit',
  },
};
