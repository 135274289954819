import type { RouteObject } from 'react-router-dom';

import { KDRouteGuard } from '@/components';
import { RouteEndpointsItemManagement } from '@/constants/route-endpoint';
import { Merks } from '@/pages/item-management/merks';
import { MerksForm } from '@/pages/item-management/merks/form';

const RouterMerks: RouteObject[] = [
  {
    path: RouteEndpointsItemManagement.BASE_MERKS,
    element: <KDRouteGuard isPrivate isAdminOnly />,
    children: [
      {
        index: true,
        element: <Merks />,
      },
      { path: RouteEndpointsItemManagement.ADD_MERK, element: <MerksForm /> },
      { path: RouteEndpointsItemManagement.EDIT_MERK, element: <MerksForm /> },
    ],
  },
];

export default RouterMerks;
