import { Navigate, Outlet } from 'react-router-dom';

import { categorizedAsAdminRoleList } from '@/constants/common/auth.constant';
import {
  RouteEndpointsAuth,
  RouteEndpointsCommon,
  RouteEndpointsStores,
} from '@/constants/route-endpoint';
import { useAuthStore } from '@/stores/auth';

import type { KDRouteGuardProps } from './KDRouteGuard.types';

const KDRouteGuard: React.FC<KDRouteGuardProps> = ({
  isPrivate = false,
  isAdminOnly = false,
}) => {
  const { isAuthenticated, userInfo } = useAuthStore((state) => state);

  if (isPrivate) {
    const isValidAdmin = categorizedAsAdminRoleList?.includes(
      userInfo?.role ?? ''
    );

    if (isAdminOnly && isValidAdmin && isAuthenticated) {
      return <Outlet />;
    } else if (isAdminOnly && !isValidAdmin) {
      return <Navigate to={RouteEndpointsStores.BASE_STORES} replace />;
    } else {
      return isAuthenticated ? (
        <Outlet />
      ) : (
        <Navigate to={RouteEndpointsAuth.LOGIN} replace />
      );
    }
  }

  return !isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate
      to={
        userInfo?.role === 'SALES'
          ? RouteEndpointsStores.BASE_STORES
          : RouteEndpointsCommon.HOME
      }
      replace
    />
  );
};

export default KDRouteGuard;
