import type { RouteObject } from 'react-router-dom';

import { KDRouteGuard } from '@/components';
import { RouteEndpointsUsers } from '@/constants/route-endpoint';
import { Users } from '@/pages/users';
import { UsersForm } from '@/pages/users/form';

const RouterUsers: RouteObject[] = [
  {
    path: RouteEndpointsUsers.BASE_USERS,
    element: <KDRouteGuard isPrivate isAdminOnly />,
    children: [
      {
        index: true,
        element: <Users />,
      },
      { path: RouteEndpointsUsers.ADD_USER, element: <UsersForm /> },
      { path: RouteEndpointsUsers.EDIT_USER, element: <UsersForm /> },
    ],
  },
];

export default RouterUsers;
