import { Suspense, lazy } from 'react';

import { KDErrorBoundary } from '@/components';
import { KDLayoutAuthLoader } from '@/layouts';

const LoginPage = lazy(() => import('./Login.page'));

export const Login = () => {
  return (
    <KDErrorBoundary>
      <Suspense fallback={<KDLayoutAuthLoader />}>
        <LoginPage />
      </Suspense>
    </KDErrorBoundary>
  );
};
