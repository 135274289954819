const user = JSON.parse(localStorage.getItem('u_in') as string);

enum EndpointStores {
  GET_ALL_STORES = '/v1/admins/stores',
  GET_SINGLE_STORE = '/v1/admins/stores/:id',
  GET_STORE_ORDER_TRANSACTION_HISTORY = '/v1/admins/stores/:id/transactions',
  GET_STORE_MEDIA_ID = '/v1/admins/stores/upload',
  GET_STORE_MEDIA_PREVIEW = '/v1/admins/stores/assets/:id',
  GET_STORES_BY_SALES = '/v1/admins/sales/:salesId/stores',
  CREATE_STORE = '/v1/admins/stores',
  UPDATE_STORE = '/v1/admins/stores/:id',
  GET_SINGLE_STORE_SALES = '/v1/sales/stores/:id',
  GET_STORE_MEDIA_PREVIEW_SALES = '/v1/sales/stores/assets/:id',
  GET_STORE_ORDER_TRANSACTION_HISTORY_SALES = '/v1/sales/stores/:id/transactions',
  DELETE_STORE = '/v1/admins/stores/:id',
}

export default EndpointStores;
