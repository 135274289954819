import { Suspense, lazy } from 'react';

import { KDErrorBoundary } from '@/components';
import { KDLayoutDashboardLoader } from '@/layouts';

const ItemsFormPage = lazy(() => import('./ItemsForm.page'));

export const ItemsForm = () => {
  return (
    <KDErrorBoundary>
      <Suspense fallback={<KDLayoutDashboardLoader />}>
        <ItemsFormPage />
      </Suspense>
    </KDErrorBoundary>
  );
};
