import { DefaultOptionType } from 'antd/es/select';
import { type AxiosError, HttpStatusCode } from 'axios';

import type { TCommonAPIError } from '@/types/common';

export const handleAxiosAPIError = (
  error: AxiosError<TCommonAPIError | undefined>
): TCommonAPIError => {
  let errorResponse;

  if (error?.response) {
    if (error.response?.data) {
      errorResponse = error.response.data;
    } else {
      errorResponse = {
        code: error.response?.status,
        message: error?.message,
        success: false,
      };
    }
  } else {
    errorResponse = {
      code: error?.status ?? HttpStatusCode.RequestTimeout,
      message: error?.message || 'Please check your connection!',
      success: false,
    };
  }

  if (
    errorResponse.code === HttpStatusCode.InternalServerError ||
    !errorResponse.message
  ) {
    errorResponse = {
      code: errorResponse.code,
      message: 'Sorry, Something Went Wrong',
      success: false,
    };
  }

  return errorResponse;
};

export function formatPhoneNumberPayloadBE(
  val: string | number | null | undefined
) {
  if (val === 0 || val === '0') return '62';
  if (val === 62 || val === '62') return '62';
  if (val && String(val)?.[0] === '0') {
    return `62${String(val)?.substring(1, String(val)?.length)}`;
  }
  if (val && String(val)?.[0] === '6' && String(val)?.[1] === '2') {
    return `62${String(val)?.substring(2, String(val)?.length)}`;
  }

  return `62${val ?? ''}`;
}

export function formatPhoneNumberInForm(val: string) {
  if (val && String(val)?.[0] === '6' && String(val)?.[1] === '2') {
    return String(val)?.substring(2, String(val)?.length);
  }

  return val;
}

export function formatPhoneNumberInShowData(val: string) {
  return `+${val}`;
}

export function formatSortDirParamsBE(
  val: 'ascend' | 'descend' | string | undefined
) {
  switch (val) {
    case 'ascend':
      return 'asc';
    case 'descend':
      return 'desc';
    default:
      return val;
  }
}

export function formatSortDirAntd(val: 'asc' | 'desc' | string | undefined) {
  switch (val) {
    case 'asc':
      return 'ascend';
    case 'desc':
      return 'descend';
    default:
      return val;
  }
}

export function formatPrice(val: number | string) {
  return `IDR ${val}`;
}

export function filterSelectOptionByLabel(
  input: string,
  option: DefaultOptionType | undefined
) {
  return String(option?.label ?? '')
    ?.toLowerCase()
    ?.includes(input?.toLowerCase());
}

export function convertDDMMDateToMMDDDate(date: string) {
  const splitted = date?.split('/');
  return `${splitted?.[1]}/${splitted?.[0]}/${splitted?.[2]}`;
}
