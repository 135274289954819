import type { RouteObject } from 'react-router-dom';

import { KDRouteGuard } from '@/components';
import { RouteEndpointsStores } from '@/constants/route-endpoint';
import { Stores } from '@/pages/stores';
import { StoresDetail } from '@/pages/stores/detail';
import { StoresForm } from '@/pages/stores/form';

const RouterStores: RouteObject[] = [
  {
    path: RouteEndpointsStores.BASE_STORES,
    element: <KDRouteGuard isPrivate />,
    children: [
      {
        index: true,
        element: <Stores />,
      },
      { path: RouteEndpointsStores.ADD_STORE, element: <StoresForm /> },
      { path: RouteEndpointsStores.EDIT_STORE, element: <StoresForm /> },

      {
        path: RouteEndpointsStores.DETAIL_STORE,
        element: <StoresDetail />,
      },
    ],
  },
];

export default RouterStores;
