import type { RouteObject } from 'react-router-dom';

import { KDRouteGuard } from '@/components';
import { RouteEndpointsItemManagement } from '@/constants/route-endpoint';
import { Items } from '@/pages/item-management/items';
import { ItemsForm } from '@/pages/item-management/items/form';

const RouterItems: RouteObject[] = [
  {
    path: RouteEndpointsItemManagement.BASE_ITEMS,
    element: <KDRouteGuard isPrivate isAdminOnly />,
    children: [
      {
        index: true,
        element: <Items />,
      },
      { path: RouteEndpointsItemManagement.ADD_ITEM, element: <ItemsForm /> },
      { path: RouteEndpointsItemManagement.EDIT_ITEM, element: <ItemsForm /> },
    ],
  },
];

export default RouterItems;
