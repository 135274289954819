import { createBrowserRouter } from 'react-router-dom';

import RouterAuth from './auth';
import RouterCommon from './common';
import RouterItemManagement from './item-management';
import RouterStores from './stores';
import RouterUsers from './users';
import RouterOrders from './order-management';
import RouterPayments from './payment';

export const router = createBrowserRouter([
  ...RouterAuth,
  ...RouterCommon,
  ...RouterStores,
  ...RouterItemManagement,
  ...RouterUsers,
  ...RouterOrders,
  ...RouterPayments,
]);
