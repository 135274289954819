import { Suspense, lazy } from 'react';

import { KDErrorBoundary } from '@/components';
import { KDLayoutDashboardLoader } from '@/layouts';

const MerksPage = lazy(() => import('./Merks.page'));

export const Merks = () => {
  return (
    <KDErrorBoundary>
      <Suspense fallback={<KDLayoutDashboardLoader />}>
        <MerksPage />
      </Suspense>
    </KDErrorBoundary>
  );
};
