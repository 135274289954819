enum RouteEndpointsStores {
  BASE_STORES = '/store-management',
  DETAIL_STORE = ':storeName',
  DETAIL_INVOICE_STORE = ':storeName/:invoiceId',
  EDIT_STORE = 'edit/:storeName',
  EDIT_INVOICE_STORE = 'edit/:storeName/:invoiceId',
  ADD_STORE = 'add',
}

export default RouteEndpointsStores;
