import { Suspense, lazy } from 'react';

import { KDErrorBoundary } from '@/components';

import KDLayoutAuthLoader from './KDLayoutAuth.loader';
import type { KDLayoutAuthProps } from './KDLayoutAuth.type';

const KDLayoutAuthComponent = lazy(() => import('./KDLayoutAuth.component'));

const KDLayoutAuth: React.FC<KDLayoutAuthProps> = (props) => {
  return (
    <KDErrorBoundary>
      <Suspense fallback={<KDLayoutAuthLoader />}>
        <KDLayoutAuthComponent {...props} />
      </Suspense>
    </KDErrorBoundary>
  );
};

export { KDLayoutAuth, type KDLayoutAuthProps, KDLayoutAuthLoader };
