import type { RouteObject } from 'react-router-dom';

import { KDRouteGuard } from '@/components';
import { RouteEndpointsPayment } from '@/constants/route-endpoint';
import { Payments } from '@/pages/payment';
import { PaymentDetail } from '@/pages/payment/detail';
import { EditPayment } from '@/pages/payment/edit';

const RouterPayments: RouteObject[] = [
  {
    path: RouteEndpointsPayment.BASE_PAYMENTS,
    element: <KDRouteGuard isPrivate isAdminOnly />,
    children: [
      {
        index: true,
        element: <Payments />,
      },
      {
        path: RouteEndpointsPayment.DETAIL_PAYMENT,
        element: <PaymentDetail />,
      },
      {
        path: RouteEndpointsPayment.EDIT_PAYMENT,
        element: <EditPayment />,
      },
    ],
  },
];

export default RouterPayments;
