// Colors
// -----------------------------------------------------------
// Color palettes defined for use throughout the design system.

// CAUTION! If you want to change the value, or want to
// add more colors, please sync up in `./styles/scss/variables/
// _colors.scss` too to prevent confusion.

/**
 * ![#FF6500](https://placehold.co/2x2/FF6500/FF6500.webp)
 * ![#E7F0F8](https://placehold.co/2x2/E7F0F8/E7F0F8.webp)
 * ![#FEFFD2](https://placehold.co/2x2/FEFFD2/FEFFD2.webp)
 * ![#FFC96F](https://placehold.co/2x2/FFC96F/FFC96F.webp)
 * ![#DC5F00](https://placehold.co/2x2/DC5F00/DC5F00.webp)
 * ![#0B467B](https://placehold.co/2x2/0B467B/0B467B.webp)
 * ![#08355C](https://placehold.co/2x2/08355C/08355C.webp)
 */
export const kColorPrimary = {
  /** ![#FF6500](https://placehold.co/2x2/FF6500/FF6500.webp) */
  main: '#FF6500',
  /** ![#E7F0F8](https://placehold.co/2x2/E7F0F8/E7F0F8.webp) */
  '0': '#E7F0F8',
  /** ![#FEFFD2](https://placehold.co/2x2/FEFFD2/FEFFD2.webp) */
  '1': '#FEFFD2',
  /** ![#FFC96F](https://placehold.co/2x2/FFC96F/FFC96F.webp) */
  '2': '#FFC96F',
  /** ![#DC5F00](https://placehold.co/2x2/DC5F00/DC5F00.webp) */
  '3': '#DC5F00',
  /** ![#0B467B](https://placehold.co/2x2/0B467B/0B467B.webp) */
  '4': '#0B467B',
  /** ![#08355C](https://placehold.co/2x2/08355C/08355C.webp) */
  '5': '#08355C',
} as const;

/**
 * ![#EE5046](https://placehold.co/2x2/EE5046/EE5046.webp)
 * ![#FFF3F2](https://placehold.co/2x2/FFF3F2/FFF3F2.webp)
 * ![#FCDCDA](https://placehold.co/2x2/FCDCDA/FCDCDA.webp)
 * ![#F8B9B5](https://placehold.co/2x2/F8B9B5/F8B9B5.webp)
 * ![#F1736B](https://placehold.co/2x2/F1736B/F1736B.webp)
 * ![#CD4239](https://placehold.co/2x2/CD4239/CD4239.webp)
 * ![#AC342D](https://placehold.co/2x2/AC342D/AC342D.webp)
 */
export const kColorDanger = {
  /** ![#EE5046](https://placehold.co/2x2/EE5046/EE5046.webp) */
  main: '#EE5046',
  /** ![#FFF3F2](https://placehold.co/2x2/FFF3F2/FFF3F2.webp) */
  '0': '#FFF3F2',
  /** ![#FCDCDA](https://placehold.co/2x2/FCDCDA/FCDCDA.webp) */
  '1': '#FCDCDA',
  /** ![#F8B9B5](https://placehold.co/2x2/F8B9B5/F8B9B5.webp) */
  '2': '#F8B9B5',
  /** ![#F1736B](https://placehold.co/2x2/F1736B/F1736B.webp) */
  '3': '#F1736B',
  /** ![#CD4239](https://placehold.co/2x2/CD4239/CD4239.webp) */
  '4': '#CD4239',
  /** ![#AC342D](https://placehold.co/2x2/AC342D/AC342D.webp) */
  '5': '#AC342D',
} as const;

/**
 * ![#252525](https://placehold.co/2x2/252525/252525.webp)
 * ![#FFFFFF](https://placehold.co/2x2/FFFFFF/FFFFFF.webp)
 * ![#D6D7D8](https://placehold.co/2x2/D6D7D8/D6D7D8.webp)
 * ![#ADAEB1](https://placehold.co/2x2/ADAEB1/ADAEB1.webp)
 * ![#838689](https://placehold.co/2x2/838689/838689.webp)
 * ![#272a2f](https://placehold.co/2x2/272a2f/272a2f.webp)
 * ![#141518](https://placehold.co/2x2/141518/141518.webp)
 */
export const kColorNeutral = {
  /** ![#252525](https://placehold.co/2x2/252525/252525.webp) */
  main: '#252525',
  /** ![#FFFFFF](https://placehold.co/2x2/FFFFFF/FFFFFF.webp) */
  '0': '#FFFFFF',
  /** ![#D6D7D8](https://placehold.co/2x2/D6D7D8/D6D7D8.webp) */
  '1': '#D6D7D8',
  /** ![#ADAEB1](https://placehold.co/2x2/ADAEB1/ADAEB1.webp) */
  '2': '#ADAEB1',
  /** ![#838689](https://placehold.co/2x2/838689/838689.webp) */
  '3': '#838689',
  /** ![#272a2f](https://placehold.co/2x2/272a2f/272a2f.webp) */
  '4': '#272a2f',
  /** ![#141518](https://placehold.co/2x2/141518/141518.webp) */
  '5': '#141518',
} as const;

/**
 * ![#F4F6FA](https://placehold.co/2x2/F4F6FA/F4F6FA.webp)
 */
export const kColorBackground = {
  /** ![#F4F6FA](https://placehold.co/2x2/F4F6FA/F4F6FA.webp) */
  dashboard: '#F4F6FA',
} as const;
