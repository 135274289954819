import { Layout, Skeleton } from 'antd';

import '@/styles/scss/utils/_padding.scss';

import './KDLayoutDashboard.style.scss';
import './subcomponent/header/Header.style.scss';
import './subcomponent/sidebar/Sidebar.style.scss';

const { Content, Header, Sider } = Layout;

const KDLayoutDashboardLoader: React.FC = () => {
  return (
    <Layout className="kd_layout_dashboard">
      <Sider
        className="kd_layout_dashboard__sidebar"
        style={{
          position: 'fixed',
          bottom: 0,
          top: 0,
          left: 0,
          overflow: 'auto',
        }}
        width={264}>
        <div className="p--3">
          <Skeleton.Button
            rootClassName="kd_layout_dashboard__root_skeleton mb--2"
            style={{ width: 150 }}
          />
          <Skeleton.Button
            rootClassName="kd_layout_dashboard__root_skeleton mb--2"
            style={{ width: 130 }}
          />
          <Skeleton.Button
            rootClassName="kd_layout_dashboard__root_skeleton mb--2"
            style={{ width: 165 }}
          />
        </div>
      </Sider>
      <Layout className="kd_layout_dashboard__inner kd_layout_dashboard__inner--expanded">
        <Header className="kd_layout_dashboard__header">
          <div style={{ marginLeft: 'auto', display: 'flex', gap: '0.5rem' }}>
            <Skeleton.Avatar rootClassName="kd_layout_dashboard__root_skeleton" />
            <Skeleton.Button
              rootClassName="kd_layout_dashboard__root_skeleton"
              style={{ width: 120 }}
            />
          </div>
        </Header>
        <div
          style={{ display: 'flex', gap: '0.5rem' }}
          className="kd_layout_dashboard__breadcrumb">
          <Skeleton.Input
            rootClassName="kd_layout_dashboard__root_skeleton mb--2"
            size="small"
          />
          /
          <Skeleton.Input
            rootClassName="kd_layout_dashboard__root_skeleton mb--2"
            size="small"
          />
        </div>

        <Content className="kd_layout_dashboard__content">
          <Skeleton className="mb--6" />
          <Skeleton />
        </Content>
      </Layout>
    </Layout>
  );
};

export default KDLayoutDashboardLoader;
