import { Suspense, lazy } from 'react';

import { KDErrorBoundary } from '@/components';
import { KDLayoutDashboardLoader } from '@/layouts';

const UsersPage = lazy(() => import('./Users.page'));

export const Users = () => {
  return (
    <KDErrorBoundary>
      <Suspense fallback={<KDLayoutDashboardLoader />}>
        <UsersPage />
      </Suspense>
    </KDErrorBoundary>
  );
};
