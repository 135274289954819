enum EndpointMerks {
  GET_ALL_MERKS = '/v1/admins/brands',
  GET_ALL_ACTIVE_MERKS = '/v1/admins/active-brands',
  GET_SINGLE_MERK = '/v1/admins/brands/:name',
  CREATE_MERK = '/v1/admins/brands',
  UPDATE_MERK = '/v1/admins/brands/:name',
  DELETE_MERK = '/v1/admins/brands/:id',
}

export default EndpointMerks;
