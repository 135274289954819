import { Suspense, lazy } from 'react';

import { KDErrorBoundary } from '@/components';

import KDLayoutDashboardLoader from './KDLayoutDashboard.loader';
import type { KDLayoutDashboardProps } from './KDLayoutDashboard.type';

const KDLayoutDashboardComponent = lazy(
  () => import('./KDLayoutDashboard.component')
);

const KDLayoutDashboard: React.FC<KDLayoutDashboardProps> = (props) => {
  return (
    <KDErrorBoundary>
      <Suspense fallback={<KDLayoutDashboardLoader />}>
        <KDLayoutDashboardComponent {...props} />
      </Suspense>
    </KDErrorBoundary>
  );
};

export { KDLayoutDashboard, KDLayoutDashboardProps, KDLayoutDashboardLoader };
