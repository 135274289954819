enum EndpointItems {
  GET_ALL_ITEMS = '/v1/admins/brand-items',
  GET_SINGLE_ITEM = '/v1/admins/brand-items/:name',
  CREATE_ITEM = '/v1/admins/brand-items',
  UPDATE_ITEM = '/v1/admins/brand-items/:name',
  GET_BRAND_BY_MERK = '/v1/admins/brands/:id/brand-items',
  GET_BRAND_BY_MERK_SALES = '/v1/sales/brands/:id/brand-items',
  DELETE_BRAND_ITEM = '/v1/admins/brand-items/:id',
}

export default EndpointItems;
