import type { RouteObject } from 'react-router-dom';

import { KDRouteGuard } from '@/components';
import { RouteEndpointsOrders } from '@/constants/route-endpoint';
import { Orders } from '@/pages/order-management';
import { OrderForm } from '@/pages/order-management/form';
import { OrderDetail } from '@/pages/order-management/detail';
import { LegacyDetail } from '@/pages/order-management/invoice';

const RouterOrders: RouteObject[] = [
  {
    path: RouteEndpointsOrders.BASE_ORDERS,
    element: <KDRouteGuard isPrivate />,
    children: [
      {
        index: true,
        element: <Orders />,
      },
      { path: RouteEndpointsOrders.ADD_ORDER, element: <OrderForm /> },
      { path: RouteEndpointsOrders.DETAIL_ORDER, element: <OrderDetail /> },
    ],
  },
  {
    path: RouteEndpointsOrders.BASE_ORDERS,
    element: <KDRouteGuard isPrivate isAdminOnly />,
    children: [
      { path: RouteEndpointsOrders.EDIT_ORDER, element: <OrderForm /> },
    ],
  },
  {
    path: RouteEndpointsOrders.DETAIL_LEGACY,
    element: <KDRouteGuard isPrivate />,
    children: [{ index: true, element: <LegacyDetail /> }],
  },
];

export default RouterOrders;
