import type { RouteObject } from 'react-router-dom';

import { KDRouteGuard } from '@/components';
import { RouteEndpointsAuth } from '@/constants/route-endpoint';
import { Login } from '@/pages/auth/login';

const RouterAuth: RouteObject[] = [
  {
    path: RouteEndpointsAuth.LOGIN,
    element: <KDRouteGuard />,
    children: [{ index: true, element: <Login /> }],
  },
];

export default RouterAuth;
